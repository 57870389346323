<template>
  <v-dialog v-model="showUploadManager" width="800">
    <v-card class="pa-6">
      <div class="body">
        <div class="table-responsive">
          <table class="table table-hover vertical-align">
            <thead>
              <tr class="text-center">
                <th>#</th>
                <th>Imej</th>
                <th>Nama</th>
                <th>Saiz</th>
                <th>Status</th>
                <th>Tindakan</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!file" @click="$refs.fileUpload.click()">
                <td colspan="12">
                  <div class="text-center pa-4">
                    {{ propFile.name }}
                  </div>
                </td>
              </tr>
              <tr class="text-center" v-if="file">
                <td>{{ file ? 1 : "" }}</td>
                <td>
                  <img class="td-image-thumb" v-if="mediaImage" :src="this.filePreview" />
                  <span v-else>No Image</span>
                </td>
                <td>
                  <div class="filename" style="
                      white-space: nowrap;
                      max-width: 200px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      display: inline-block;
                    ">
                    {{ file ? file.name : "" }}
                  </div>
                  <div class="progress">
                    <div :class="{
                      'progress-bar': true,
                      'bg-danger': file.error,
                    }" role="progressbar" :style="{ width: progress + '%' }">
                      {{ progress }}%
                    </div>
                  </div>
                </td>
                <td>
                  {{ file ? file.size : 0 | formatSize }}
                </td>
                <td v-if="file.error">{{ file.error }}</td>
                <td v-else>{{ status }}</td>
                <td>
                  <v-btn class="btn btn-icon btn-light btn-hover-primary btn-sm" icon @click="clear">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                      <inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="upload">
          <div class="btn-group">
            <input type="file" ref="fileUpload" style="display: none" @change="runUploadFile" @click="inputClick" />
            <button type="button" class="btn btn-primary" @change="runUploadFile" @click="$refs.fileUpload.click()"> Pilih
              Dokumen
            </button>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>


<script>
import {
  UPLOAD_MEDIA,
  UPLOAD_MEDIA_GUEST,
} from "@/core/services/store/mediaupload.module";

export default {
  components: {
    //
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    propFile: {
      type: Object,
      default: () => { return {}; },
    },

    propGuest: {
      type: Boolean,
      default: false,
    },

    propMasterId: {
      type: Number,
      default: 0,
    },

  },

  data() {
    return {
      file: null,
      filePreview: null,
      success: false,
      error: false,
      progress: 0,
      mediaImage: false,
      status: '',
    };
  },

  watch: {
    showUploadManager(newValue) {
      if (newValue == false) {
        //nfh-console.log('CLOSING showUploadManager');
        this.$refs.fileUpload.value = "";
        this.file = null;
      }
    }

  },

  computed: {
    showUploadManager: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    masterId: {
      get() {
        return this.propMasterId;
      },
    },

    isError: {
      get() {
        return this.error;
      },
    },

    isGuest: {
      get() {
        return this.propGuest;
      },
    }

  },

  methods: {
    inputClick() {
      this.file = null;
    },

    async clear() {
      this.$refs.fileUpload.value = "";
      this.file = null;
    },

    async runUploadFile(value) {
      setTimeout(async () => {
        await this.uploadFile(value);
      });
    },

    async uploadFile(value) {
      this.file = value.target.files[0];
      this.selectImgFile(value);

      this.progress = 30;
      this.status = "Sedia untuk dimuat naik";

      await this.$store.dispatch((!!this.isGuest) ? UPLOAD_MEDIA_GUEST : UPLOAD_MEDIA, Object.assign({
          name: this.propFile.name,
          media: this.file,
          data: JSON.stringify(this.propFile),
        }, (!!this.isGuest) ? { formId: this.masterId } : { applicationId: this.masterId }))
        .then((resp) => {
          this.progress = 60;
          this.status = "Sedang dimuat naik";

          //nfh-console.log('uploadFile resp:', resp);
          setTimeout(async () => {
            if (resp.data.success == true) {
              this.error = false;
              this.success = true;
              this.progress = 100;
              this.status = "SELESAI";
              this.$emit("onFinishFileUpload", resp);
            }
          }, 2000);
        })
        .catch((err) => {
          this.error = true;
          this.success = false;
          this.status = "GAGAL";
        });
    },

    selectImgFile(value) {
      let fileUpload = value.target.files[0];
      let imgFile = fileUpload;
      if (imgFile) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.filePreview = e.target.result;
          if (imgFile.type.substr(0, 6) === "image/") {
            this.mediaImage = true;
          }
        };
        reader.readAsDataURL(imgFile);
        this.$emit("fileUpload", imgFile);
      }
    },

  },

};
</script>


<style>
.body {
  /* padding: 2em; */
}

.body .btn-group .dropdown-menu {
  display: block;
  visibility: collapse;
  transition: all 0.2s;
}

.body .btn-group:hover>.dropdown-menu {
  visibility: visible;
}

.body label.dropdown-item {
  margin-bottom: 0;
}

.body .btn-group {
  margin-right: 1rem;
}

.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}

.body .filename {
  margin-bottom: 0.3rem;
}

.body .btn-is-option {
  margin-top: 0.25rem;
}

.body .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.body .edit-image img {
  max-width: 100%;
}

.body .edit-image-tool {
  margin-top: 0.6rem;
}

.body .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}

.body .footer-status {
  padding-top: 0.4rem;
}

.body .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.body .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
