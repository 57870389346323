var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-15" },
    [
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          { staticClass: "table table-head-custom table-vertical-center" },
          [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.localList, function (data, i) {
                return _c("tr", { key: data.id, staticClass: "text-center" }, [
                  _c("td", { attrs: { scope: "row" } }, [
                    _vm._v(_vm._s(i + 1)),
                  ]),
                  _c("td", { staticClass: "text-start" }, [
                    data.mandatory == true && data.fulfilled == true
                      ? _c("span", [
                          _vm._v(" " + _vm._s(data.name)),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(" *")]
                          ),
                        ])
                      : data.mandatory == true && data.fulfilled != true
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(data.name) +
                                " " +
                                _vm._s(data.mandatory ? "*" : "") +
                                " "
                            ),
                          ]
                        )
                      : _c("span", [_vm._v(" " + _vm._s(data.name) + " ")]),
                  ]),
                  _c("td", [
                    data.media_url
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "\n              label label-md label-inline\n              font-weight-bold\n              h-25\n              label-light-success\n            ",
                            staticStyle: {
                              "white-space": "nowrap",
                              "max-width": "300px",
                              "text-overflow": "ellipsis",
                              overflow: "hidden",
                              display: "inline-block",
                            },
                          },
                          [
                            data.media_url
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: data.media_url,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.media_url && data.media_name
                                            ? data.media_name
                                            : "Tiada"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      : data.mandatory == true
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Tiada ")]
                        )
                      : _c("span", [_vm._v(" Tiada ")]),
                  ]),
                  _c("td", [
                    data.media_url
                      ? _c(
                          "a",
                          {
                            staticStyle: {
                              color: "blue",
                              "font-weight": "bold",
                              "font-style": "oblique",
                            },
                            attrs: { href: data.media_url, target: "_blank" },
                          },
                          [_vm._v(" Lihat fail ")]
                        )
                      : data.mandatory == true
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(" Tiada ")]
                        )
                      : _c("span", [_vm._v(" Tiada ")]),
                  ]),
                  _c(
                    "td",
                    [
                      !data.media_url && !_vm.downloadOnly
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn btn-icon btn-light btn-hover-primary btn-sm mx-3",
                              attrs: { icon: "", disabled: _vm.isReadOnly },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onUploadFile(data, i)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-primary",
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src: "/media/svg/icons/Files/Upload.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : data.media_url && !_vm.downloadOnly
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn btn-icon btn-light btn-hover-danger btn-sm",
                              attrs: { icon: "", disabled: _vm.isReadOnly },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showAlertDeleteMedia(i)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-danger",
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src: "/media/svg/icons/General/Trash.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm.downloadOnly
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn btn-icon btn-light btn-hover-warning btn-sm",
                              attrs: { icon: "", disabled: _vm.isReadOnly },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.refreshFile(i)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-warning",
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src: "/media/svg/icons/General/Update.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn btn-icon btn-light btn-hover-danger btn-sm",
                              attrs: { icon: "", disabled: "" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-disabled",
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src: "/media/svg/icons/General/Upload.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
      _c("ConfirmationDialog", {
        attrs: {
          title: "Padam",
          content: "Anda pasti ingin memadam dokumen ini?",
          proceedButton: "Padam",
          backButton: "Batal",
          showProceedButton: true,
          showBackButton: true,
        },
        on: {
          onProceed: function ($event) {
            return _vm.deleteFile()
          },
        },
        model: {
          value: _vm.showConfDelDialog,
          callback: function ($$v) {
            _vm.showConfDelDialog = $$v
          },
          expression: "showConfDelDialog",
        },
      }),
      _c("UploadManager", {
        attrs: {
          propFile: _vm.uploadData,
          propGuest: _vm.isGuest,
          propMasterId: _vm.masterId,
        },
        on: { onFinishFileUpload: _vm.onFinishFileUpload },
        model: {
          value: _vm.showUploadManager,
          callback: function ($$v) {
            _vm.showUploadManager = $$v
          },
          expression: "showUploadManager",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", { staticClass: "text-center" }, [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { staticClass: "text-left", attrs: { scope: "col" } }, [
          _vm._v("Perkara"),
        ]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Nama dokumen")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
        _c(
          "th",
          { staticStyle: { "min-width": "200px" }, attrs: { scope: "col" } },
          [_vm._v("Tindakan")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }