<template>
  <div class="mb-15">
    <div class="table-responsive">
      <table class="table table-head-custom table-vertical-center">
        <thead class="thead-dark">
          <tr class="text-center">
            <th scope="col">#</th>
            <th scope="col" class="text-left">Perkara</th>
            <th scope="col">Nama dokumen</th>
            <th scope="col">Status</th>
            <th scope="col" style="min-width: 200px">Tindakan</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="(data, i) in localList" :key="data.id">
            <td scope="row">{{ i + 1 }}</td>
            <td class="text-start">
              <span v-if="data.mandatory == true && data.fulfilled == true">
                {{ data.name }}<span style="color: red; font-weight: bold;">&nbsp;*</span>
              </span>
              <span v-else-if="data.mandatory == true && data.fulfilled != true" style="color: red; font-weight: bold;">
                {{ data.name }}&nbsp;{{ data.mandatory ? "*" : "" }}
              </span>
              <span v-else>
                {{ data.name }}
              </span>
            </td>
            <td>
              <span v-if="data.media_url" class="
                label label-md label-inline
                font-weight-bold
                h-25
                label-light-success
              " style="
                white-space: nowrap;
                max-width: 300px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
              ">
                <a v-if="data.media_url" :href="data.media_url" target="_blank">
                  {{ data.media_url && data.media_name ? data.media_name : "Tiada" }}
                </a>
              </span>
              <span v-else-if="data.mandatory == true" style="color: red; font-weight: bold;">
                Tiada
              </span>
              <span v-else>
                Tiada
              </span>
            </td>
            <td>
              <a v-if="data.media_url" :href="data.media_url" target="_blank"
                style="color: blue; font-weight: bold; font-style: oblique;">
                Lihat fail
              </a>
              <span v-else-if="data.mandatory == true" style="color: red; font-weight: bold;">
                Tiada
              </span>
              <span v-else>
                Tiada
              </span>
            </td>
            <td>
              <v-btn v-if="!data.media_url && !downloadOnly" class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                icon :disabled="isReadOnly" @click.prevent="onUploadFile(data, i)">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/media/svg/icons/Files/Upload.svg"></inline-svg>
                </span>
              </v-btn>

              <v-btn v-else-if="data.media_url && !downloadOnly" class="btn btn-icon btn-light btn-hover-danger btn-sm"
                icon :disabled="isReadOnly" @click.prevent="showAlertDeleteMedia(i)">
                <span class="svg-icon svg-icon-md svg-icon-danger">
                  <inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
                </span>
              </v-btn>

              <v-btn v-else-if="downloadOnly" class="btn btn-icon btn-light btn-hover-warning btn-sm" icon
                :disabled="isReadOnly" @click.prevent="refreshFile(i)">
                <span class="svg-icon svg-icon-md svg-icon-warning">
                  <inline-svg src="/media/svg/icons/General/Update.svg"></inline-svg>
                </span>
              </v-btn>

              <v-btn v-else class="btn btn-icon btn-light btn-hover-danger btn-sm" icon disabled>
                <span class="svg-icon svg-icon-md svg-icon-disabled">
                  <inline-svg src="/media/svg/icons/General/Upload.svg"></inline-svg>
                </span>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <ConfirmationDialog v-model="showConfDelDialog" title="Padam" content="Anda pasti ingin memadam dokumen ini?"
      proceedButton="Padam" backButton="Batal" :showProceedButton="true" :showBackButton="true" @onProceed="deleteFile()">
    </ConfirmationDialog>

    <UploadManager v-model="showUploadManager" :propFile="uploadData" :propGuest="isGuest" :propMasterId="masterId"
      @onFinishFileUpload="onFinishFileUpload"></UploadManager>

  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  REFRESH_FILE,
  DELETE_MEDIA,
  REFRESH_FILE_GUEST,
  DELETE_MEDIA_GUEST,
} from "@/core/services/store/mediaupload.module";

import {
  getSecuredFile,
} from "@/core/services/helper.service";

import UploadManager from "@/view/pages/widgets/UploadManager.vue";
// import ConfirmationDialog from "@/view/pages/widgets/ConfirmationDialog.vue";

export default {
  name: "DocUploadList",

  components: {
    UploadManager,
    // ConfirmationDialog,
  },

  props: {
    propList: {
      type: Array,
      default: () => { return []; },
    },
    propGuest: {
      type: Boolean,
      default: false,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
    propMasterId: {
      type: Number,
      default: 0,
    },

  },

  data() {
    return {
      delIndex: null,
      localList: [],
      uploadData: {},
      uploadIndex: -1,
      showConfDelDialog: false,
      showUploadManager: false,
      buttonStateClass: "",
      buttonStateSvg: "",
      buttonStateStatus: "true",
    };
  },

  async created() {
    //nfh-console.log(' IN:CREATED');

    //nfh-console.log("this.propList:", this.propList);
    this.localList = await Array.isArray(this.propList) ? this.propList.slice() : [];
    //nfh-console.log("this.localList:", this.localList);

    //nfh-console.log('OUT:CREATED');
  },

  mounted() {
    //nfh-console.log(' IN:MOUNTED');
    //nfh-console.log('OUT:MOUNTED');
  },

  watch: {
    async propList(newValue, oldValue) {
      //nfh-console.log(' IN:propList')
      //nfh-console.log('newValue, oldValue: ', newValue, oldValue);
      this.localList = await Array.isArray(newValue) ? newValue.slice() : [];
      //nfh-console.log('this.localList (watch): ', this.localList);
      //nfh-console.log('OUT:propList')
    },

  },

  computed: {
    ...mapGetters([
      "layoutConfig",
    ]),

    masterId: {
      get() {
        return this.propMasterId;
      },
    },

    isGuest: {
      get() {
        return !!this.propGuest;
      }
    },

    isReadOnly: {
      get() {
        return !!this.propReadOnly;
      }
    },

    downloadOnly: {
      get() {
        return this.propList[0]?.download_only ?? false;
      }
    },

    currentUploadIndex: {
      get() {
        return this.uploadIndex;
      }
    },

  },

  methods: {
    onUploadFile(theFile, index) {
      //nfh-console.log(' IN:onUploadFile', theFile);

      this.uploadData = theFile;
      this.uploadIndex = index;
      this.showUploadManager = true;

      //nfh-console.log('OUT:onUploadFile');
    },

    async onFinishFileUpload(value) {
      //nfh-console.log(' IN: onFinishFileUpload', value);

      if (value.data.success == true) {
        var data = value.data?.data;
        this.localList[this.uploadIndex].id = data.id;
        this.localList[this.uploadIndex].appid = data.application_id;
        this.localList[this.uploadIndex].formid = data.form_id;
        this.localList[this.uploadIndex].roleid = data.role_id;
        this.localList[this.uploadIndex].checklist_id = data.id;
        this.localList[this.uploadIndex].checklist_masters_id = data.checklist_masters_id;
        this.localList[this.uploadIndex].media_name = data.media_name;
        this.localList[this.uploadIndex].media_url = await getSecuredFile(data.media_url + "?id=" + (Math.random() * (999999999999 - 1000000000) + 1000000000));
        this.localList[this.uploadIndex].raw_url = data.raw_url;
        this.localList[this.uploadIndex].name = data.name;
        this.localList[this.uploadIndex].download_only = data.download_only;
        this.localList[this.uploadIndex].fulfilled = true;

        if (!data.download_only) {
          this.localList[this.uploadIndex].mandatory = data.mandatory;
        }
        this.showUploadManager = false;
      }
      this.uploadIndex = -1;

      //nfh-console.log('OUT: onFinishFileUpload', value);
    },

    showAlertDeleteMedia(_index) { //, _checklist) {
      //nfh-console.log(' IN:showAlertDeleteMedia', _index);

      this.delIndex = _index;
      this.showConfDelDialog = true;

      //nfh-console.log('OUT:showAlertDeleteMedia', _index);
    },

    deleteFile() {
      //nfh-console.log(' IN:deleteFile');

      //nfh-console.log('this.delIndex: ', this.delIndex);
      //nfh-console.log('this.localList: ', this.localList);

      if (this.localList[this.delIndex]) {
        this.$store.dispatch(this.isGuest ? DELETE_MEDIA_GUEST : DELETE_MEDIA, {
          id: this.localList[this.delIndex].checklist_id,
        })
          .then((resp) => {
            if (resp.data.success == true) {
              //nfh-console.log("BACKEND HAS DELETED THE MEDIA");
              URL.revokeObjectURL(this.localList[this.delIndex].media_url);
              //nfh-console.log('REVOKED OBJECT URL:', this.localList[this.delIndex].media_url);
              this.localList[this.delIndex].checklist_id = "";
              this.localList[this.delIndex].media_name = "";
              this.localList[this.delIndex].media_url = "";
              this.localList[this.delIndex].fulfilled = false;
            }
            else {
              //nfh-console.log('DELETE MEDIA UNSUCCESSFUL, NOT REVOKE OBJECT URL:', this.localList[this.delIndex].media_url);
            }
          })
          .catch((err) => {
            //nfh-console.log('DELETE MEDIA UNSUCCESSFUL: CATCH ERROR', err);
            //nfh-console.log('NOT REVOKE OBJECT URL:', this.localList[this.delIndex].media_url);
          })
          .finally(() => {
            this.delIndex = null;
            this.showConfDelDialog = false;
          });
      }

      //nfh-console.log('OUT:deleteFile');
    },

    async refreshFile(index) {
      //nfh-console.log(' IN:refreshFile', index);

      if (this.localList[index]) {
        let params = Object.assign({
          // role_id: this.localList[index].roleid,
          filename: this.localList[index].media_name ?? this.localList[index].name,
        }, this.isGuest ? { form_id: this.localList[index].formid } : { application_id: this.localList[index].appid });

        if (this.downloadOnly && this.localList[index].media_url) {
          URL.revokeObjectURL(this.localList[index].media_url);
          this.localList[index].media_url = null;
        }

        //nfh-console.log('params', params);
        //nfh-console.log('this.isGuest', this.isGuest);

        await this.$store.dispatch(this.isGuest ? REFRESH_FILE_GUEST : REFRESH_FILE, params)
          .then(async (resp) => {
            if (resp?.data?.success == true) {
              const data = resp.data.data;
              //nfh-console.log(`REFRESH ${this.isGuest ? 'REFRESH_FILE_GUEST' : 'REFRESH_FILE'} RESULT:`, resp);

              if (data) {
                this.localList[index].appid = data.appid;
                this.localList[index].formid = data.formid;
                // this.localList[index].roleid = data.roleid;
                this.localList[index].checklist_id = data.checklist_id;
                this.localList[index].checklist_masters_id = data.checklist_masters_id;
                this.localList[index].media_name = data.media_name;
                this.localList[index].media_url = await getSecuredFile(data.media_url + "?id=" + (Math.random() * (999999999999 - 1000000000) + 1000000000));
                this.localList[index].raw_url = data.raw_url;
                this.localList[index].name = data.name;
                this.localList[index].download_only = data.download_only;

                if (!data.download_only) {
                  this.localList[index].mandatory = data.mandatory;
                }

                //nfh-console.log(`this.propList[${index}] = `, this.propList[index])
              }
            }
          });
      }

      //nfh-console.log('OUT:refreshFile');
    },

  },

  updated() {
    //nfh-console.log(" IN:UPDATED");
    //nfh-console.log("OUT:UPDATED");
  },

  activated() {
    //nfh-console.log(" IN:ACTIVATED");
    //nfh-console.log("OUT:ACTIVATED");
  },

  deactivated() {
    //nfh-console.log(" IN:DEACTIVATED");
    //nfh-console.log("OUT:DEACTIVATED");
  },

  unmounted() {
    //nfh-console.log(" IN:UNMOUNTED");
    //nfh-console.log("OUT:UNMOUNTED");
  },

  destroyed() {
    //nfh-console.log(" IN:DESTROYED");
    //nfh-console.log("OUT:DESTROYED");
  },

  beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    //nfh-console.log("OUT:BEFORECREATE");
  },

  beforeMounted() {
    //nfh-console.log(" IN:BEFOREMOUNTED");
    //nfh-console.log("OUT:BEFOREMOUNTED");
  },

  beforeUpdate() {
    //nfh-console.log(" IN:BEFOREUPDATE");
    //nfh-console.log("OUT:BEFOREUPDATE");
  },

  beforeUnmounted() {
    //nfh-console.log(" IN:BEFOREUNMOUNTED");
    //nfh-console.log("OUT:BEFOREUNMOUNTED");
  },

  beforeDestroy() {
    //nfh-console.log(" IN:BEFOREDESTROY");

    if (this.propList.length > 0) {
      this.propList.forEach((el) => {
        if (el) {
          if (el.media_url.startsWith("blob")) {
            URL.revokeObjectURL(el.media_url);
            //nfh-console.log('REVOKED OBJECT URL:', el.media_url);
          }
          else {
            //nfh-console.log('NOT REVOKE OBJECT URL:', el.media_url);
          }
        }
        else {
          //nfh-console.log('NO CHECKLIST TO REVOKE OBJECT URL FOUND!');
        }
      });
    }

    //nfh-console.log("OUT:BEFOREDESTROY");
  },

};
</script>
