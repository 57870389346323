var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "440" },
          model: {
            value: _vm.displayCalculatorDialog,
            callback: function ($$v) {
              _vm.displayCalculatorDialog = $$v
            },
            expression: "displayCalculatorDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-4" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0 d-flex flex-wrap justify-content-end",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", light: "" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "grey darken-2" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeCalculatorDialog()
                                },
                              },
                            },
                            [_vm._v(" mdi-close ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-card-title", { staticClass: "px-0 py-0" }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "ValidationObserver",
                    { ref: "calculatorValidation" },
                    [
                      _c(
                        "v-form",
                        { staticClass: "font-weight-bold font-size-sm" },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    align: _vm.alignCenter,
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", sm: "12" },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "Gaji",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("vuetify-money", {
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: _vm.moneyDense,
                                                    "error-messages": errors,
                                                    label: _vm.label1,
                                                    placeholder:
                                                      _vm.placeholder1,
                                                    tabindex: 991,
                                                    options:
                                                      _vm.currencyTwoDecimals,
                                                    readonly: _vm.moneyWritable,
                                                    disabled: _vm.moneyDisabled,
                                                    outlined: _vm.moneyOutlined,
                                                    clearable:
                                                      _vm.moneyClearable,
                                                    valueWhenIsEmpty:
                                                      _vm.moneyWhenEmpty,
                                                    properties:
                                                      _vm.moneyProperties,
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.local.salary,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.local,
                                                        "salary",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "local.salary",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", sm: "12" },
                                    },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "Tanggungan Bulanan",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("vuetify-money", {
                                                  attrs: {
                                                    "hide-details": "auto",
                                                    dense: _vm.moneyDense,
                                                    "error-messages": errors,
                                                    label: _vm.label2,
                                                    placeholder:
                                                      _vm.placeholder2,
                                                    tabindex: 992,
                                                    options:
                                                      _vm.currencyTwoDecimals,
                                                    readonly: _vm.moneyWritable,
                                                    disabled: _vm.moneyDisabled,
                                                    outlined: _vm.moneyOutlined,
                                                    clearable:
                                                      _vm.moneyClearable,
                                                    properties:
                                                      _vm.moneyProperties,
                                                    valueWhenIsEmpty:
                                                      _vm.moneyWhenEmpty,
                                                    required: "",
                                                  },
                                                  model: {
                                                    value: _vm.local.deductions,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.local,
                                                        "deductions",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "local.deductions",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "KATEGORI MAJIKAN",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("v-select", {
                                                  attrs: {
                                                    label: "KATEGORI MAJIKAN",
                                                    "item-text": "name",
                                                    "item-value": "id",
                                                    "hide-details": "auto",
                                                    tabindex: 993,
                                                    items: _vm.emp,
                                                    "error-messages": errors,
                                                    outlined: "",
                                                    required: "",
                                                    dense: "",
                                                  },
                                                  model: {
                                                    value: _vm.local.employer,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.local,
                                                        "employer",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "local.employer",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            tabindex: "994",
                                            block: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.calculate()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-weight-bold" },
                                            [_vm._v(" Kira ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [_c("v-divider", { staticClass: "mt-0" })],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: { cols: "12", md: "12", sm: "12" },
                                    },
                                    [
                                      _c(
                                        "ValidationProvider",
                                        {
                                          attrs: {
                                            name: "Tahun pinjaman",
                                            rules: "required",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              " Sila Pilih Tempoh Bayaran Pinjaman (Tahun) : "
                                            ),
                                          ]),
                                          _c("v-slider", {
                                            attrs: {
                                              min: "1",
                                              max: "10",
                                              "tick-size": "5",
                                              ticks: "always",
                                              disabled: !_vm.calcPressed,
                                              "tick-labels": _vm.ticksLabels,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getYear(_vm.tenure)
                                              },
                                            },
                                            model: {
                                              value: _vm.slider,
                                              callback: function ($$v) {
                                                _vm.slider = $$v
                                              },
                                              expression: "slider",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("span", { staticClass: "mr-4" }, [
                                          _vm._v(" Amaun Maksimum Pinjaman: "),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            type: "number",
                                            "hide-details": "auto",
                                            step: 100,
                                            min: _vm.calculated.min,
                                            max: _vm.calculated.max,
                                            disabled: !_vm.calcPressed,
                                            outlined: "",
                                            dense: "",
                                          },
                                          on: { input: _vm.calcCustom },
                                          model: {
                                            value: _vm.calculated.amt,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.calculated,
                                                "amt",
                                                $$v
                                              )
                                            },
                                            expression: "calculated.amt",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("span", { staticClass: "mr-4" }, [
                                          _vm._v(" Kadar Keuntungan (%): "),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("vuetify-money", {
                                          attrs: {
                                            "hide-details": "auto",
                                            placeholder: _vm.placeholder6,
                                            tabindex: 996,
                                            options: _vm.percentTwoDecimals,
                                            readonly: _vm.moneyReadOnly,
                                            disabled:
                                              _vm.moneyDisabled ||
                                              !_vm.calcPressed,
                                            outlined: _vm.moneyOutlined,
                                            clearable: _vm.moneyUnclearable,
                                            valueWhenIsEmpty: _vm.placeholder5,
                                            properties: _vm.moneyProperties,
                                            dense: _vm.moneyDense,
                                            "error-messages": _vm.errors,
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.calculated.rate,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.calculated,
                                                "rate",
                                                $$v
                                              )
                                            },
                                            expression: "calculated.rate",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("span", { staticClass: "mr-4" }, [
                                          _vm._v(" Ansuran Bulanan: "),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "6" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                      },
                                      [
                                        _c("vuetify-money", {
                                          attrs: {
                                            "hide-details": "auto",
                                            dense: _vm.moneyDense,
                                            "error-messages": _vm.errors,
                                            placeholder: _vm.placeholder5,
                                            tabindex: 997,
                                            options: _vm.currencyTwoDecimals,
                                            readonly: _vm.moneyReadOnly,
                                            disabled:
                                              _vm.moneyDisabled ||
                                              !_vm.calcPressed,
                                            outlined: _vm.moneyOutlined,
                                            clearable: _vm.moneyUnclearable,
                                            properties: _vm.moneyProperties,
                                            valueWhenIsEmpty: _vm.placeholder5,
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.calculated.inst,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.calculated,
                                                "inst",
                                                $$v
                                              )
                                            },
                                            expression: "calculated.inst",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-wrap justify-content-end align-items-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.isLoading,
                            disabled: _vm.isProceedDisabled,
                            depressed: "",
                            block: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onProceed()
                            },
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: _vm.alignCenter } },
                            [
                              _c("v-col", [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      " Mohon Amaun Ini : " +
                                        _vm._s(
                                          _vm._f("fAmount")(_vm.calculated.amt)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
          _c("ErrorMsgDialog", {
            attrs: {
              proceedButton: "OK",
              title: _vm.msgERR.title,
              content: _vm.msgERR.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              isTypeError: true,
            },
            model: {
              value: _vm.msgERR.show,
              callback: function ($$v) {
                _vm.$set(_vm.msgERR, "show", $$v)
              },
              expression: "msgERR.show",
            },
          }),
          _c("InfoMsgDialog", {
            attrs: {
              title: "Info",
              proceedButton: "OK",
              content: _vm.msgINFO.message,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
            },
            model: {
              value: _vm.msgINFO.show,
              callback: function ($$v) {
                _vm.$set(_vm.msgINFO, "show", $$v)
              },
              expression: "msgINFO.show",
            },
          }),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }