<template>
  <v-card
    class="pa-2"
    height="auto"
    elevation="0"
  >
    <AlertActionable
      v-if="alertDisplayable"
      v-model="alert1.show"
      :alertMessage="alert1.message"
      :alertHtml="alert1.html"
      :alertType="alert1.type"
      :outlined="alert1.outlined"
      :bold="alert1.bold"
      :dismissable="alert1.dismissable"
      :button1Caption="alert1.button1Caption"
      :button2Caption="alert1.button2Caption"
      :button3Caption="alert1.button3Caption"
      @onAction1="alert1.onAction1"
      @onAction2="alert1.onAction2"
      @onAction3="alert1.onAction3"
    ></AlertActionable>

    <ValidationObserver
      ref="documentUploadFormValidation"
    >
      <v-form
        class="font-weight-bold font-size-sm"
      >
        <v-container
          fluid
        >
          <v-row
          >
            <v-col
              cols="12"
              md="12"
            >
              <h5 class="primary--text font-weight-bold">
                Dokumen Pengesahan
              </h5>
              <v-divider
                class="border-opacity-25"
                :thickness="2"
                color="--v-primary-base"
              ></v-divider>
              <ul>
                <li
                  v-if="propRoles.includes(2) || propRoles.includes(3) || propRoles.includes(5) || propRoles.includes(6)"
                  class="primary--text"
                >
                  <h6 class="primary--text">
                    <strong>BAHAGIAN 1:</strong> Sila <strong>muat-turun</strong> borang-borang dibawah ini bagi mendapatkan tandatangan majikan
                  </h6>
                  <DocUploadListDownload
                    :propMasterId="propertyFormId"
                    :propList="checklistDownloadStore"
                    :propReadOnly="isReadOnly"
                    :propGuest="isUseForm"
                  ></DocUploadListDownload>
                </li>
                <li>
                  <h6 class="primary--text">
                    <strong>BAHAGIAN 2:</strong> Sila <strong>muat-naik</strong> semula borang-borang yang sudah lengkap ditandatangani dibawah
                  </h6>
                  <ValidationObserver
                    ref="checklistValidation"
                  >
                    <ValidationProvider>
                      <DocUploadList
                        :propMasterId="propertyFormId"
                        :propList="checklistStore"
                        :propReadOnly="isReadOnly"
                        :propGuest="isUseForm"
                      ></DocUploadList>
                    </ValidationProvider>
                  </ValidationObserver>
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-overlay
        :value="overlay"
      >
        <v-progress-circular
          indeterminate size="64"
        ></v-progress-circular>
      </v-overlay>
    </ValidationObserver>
  </v-card>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  required,
  email,
  max,
  numeric,
} from "vee-validate/dist/rules";

import {
  generateChecklist,
  getSecuredFile,
  errorMessages,
} from "@/core/services/helper.service";

import {
  // GET_CHECKLIST_FULL,
  // GET_LATEST_FORM_SESSION,
  PURGE_CHECKLIST_MASTERS,
} from "@/core/services/store/form.module";

import {
  SHOW_APPLICATION,
  SET_APPLICATION,
} from "@/core/services/store/application.module";

import {
  DELETE_MEDIA_GUEST,
  SET_CHECKLIST_STAT,
  SET_CHECKLIST_DATA,
  SET_CHECKLIST_DOWNLOAD_DATA,
  PURGE_ALL_CHECKLIST,
} from "@/core/services/store/mediaupload.module";

import DocUploadList from "@/view/pages/widgets/DocUploadList.vue";
import DocUploadListDownload from "@/view/pages/widgets/DocUploadList.vue";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("email", {
  ...email,
  message: "E-mel mestilah sah",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "DocumentForm",

  components: {
    ValidationObserver,
    ValidationProvider,
    DocUploadListDownload,
    DocUploadList,
  },

  props: {
    propId: Number,
    propRoles: Array,

    propUseForm: {
      type: Boolean,
      default: true,
    },

    propForm: {
      type: Object,
      default: () => { return { empty: true }; },
    },

    propDocList: {
      type: Array,
      default: () => { return []; },
    },

    propReadOnly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      overlay: true,
      guestStatus: this.propUseForm || false,
      uploadData: {},
      // checklistData: [],
      // checklistDownloadData: [],
      // checklistFulfilmentCount: 0,
      mediaIndex: null,
      mediaChecklist: null,
      openCalculatorDialog: false,
      memberChecked: false,

    };
  },

  watch: {
    propDocList: function (newValue, oldValue) {
      if (Array.isArray(newValue) && newValue.length > 0) {
        //nfh-console.log('WATCH:propDocList isArray & length > 0')

        let valueDiffers = (JSON.stringify(newValue) != JSON.stringify(oldValue));
        let countDiffers = this.propDocList.length != (this.checklistStore.length || 0 + this.checklistDownloadStore.length || 0);

        if (valueDiffers || countDiffers) {
          //nfh-console.log('WATCH:propDocList old & new value not same')

          this.prepareChecklist();
          // this.mergeChecklistWithUploaded();
        }
        else {
          //nfh-console.log("Checklist is same: Nothing to update");
        }
      }
    },

    propForm: function (newValue, oldValue) {
      //nfh-console.log('propForm Changed');
      //nfh-console.log('propForm old value:', oldValue?.checklist);
      //nfh-console.log('propForm new value:', newValue?.checklist);
      //nfh-console.log("this.propForm:", this.propForm);
      if (JSON.stringify(newValue?.checklist) != JSON.stringify(oldValue?.checklist)) {
        this.prepareChecklist();
        // this.mergeChecklistWithUploaded();
      }
      else {
        //nfh-console.log("Checklist is same: Nothing to update");
      }
    },

    propUseForm: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.guestStatus = newValue;
      }
    },
  },

  computed: {
    ...mapGetters([
      "checklistStore",
      "checklistDownloadStore",
      "checklistStatStore",
    ]),

    alertDisplayable() {
      return !!(this.propRoles.includes(5) || this.propRoles.includes(6));
    },

    isInArray: function () {
      return this.propRoles.includes(value);
    },

    isUseForm() {
      return !!this.propUseForm;
    },

    isGuest() {
      return !!this.guestStatus;
    },

    isReadOnly() {
      return !!this.propReadOnly;
    },

    propertyFormId() {
      return this?.propForm?.id ?? 0;
    },

    // checklist() {
    //   return this.checklistData;
    // },

    // checklistDownload() {
    //   return this.checklistDownloadData;
    // },

    propertiesForm() {
      return this.propForm;
    },

    // checklistFulfilled() {
    //   return this.checklistFulfilmentCount == 0;
    // },

    // isExportable() {
    //   return false;
    // },

    // checklistFulfillment() {
    //   return this.checklistFulfilmentCount == 0;
    // },

    alert1() {
      return {
        show: true,
        type: "notype",
        message: "",
        // html: this.currentUser.phone_verified != true ? `<br/>Perkara penting sebelum meneruskan permohonan:<ol><li>Pastikan No. Kad Pengenalan anda adalah betul DAN milik anda</li><li>Masukkan No. Telefon bimbit anda yang aktif SERTA perlu disahkan</li><li>Masukkan Alamat E-Mel anda yang aktif SERTA perlu disahkan</li></ol>` : "",
        html: this.guestStatus
          ? `
            <strong>Kaedah menghantar dokumen-dokumen melalui online:</strong>
            <ol style="text-align: justify;">
              <li>
                Muat turun & cetak borang/surat dibahagian 1, dapatkan tandatangan/pengesahan & scan semula dalam bentuk PDF atau imej.
              </li>
              <li>
                Muat naik borang/surat dalam bentuk PDF/imej dibahagian 2 di ruang dokumen yang disediakan.
              </li>
              <li>
                Muat naik dokumen sokongan lain seperti salinan IC, penyata bank dan lain-lain di ruang yang disediakan.
              </li>
              <li>
                Senarai dokumen yang berwarna <span style="color: red;">merah*</span> adalah dokumen yang diperlukan untuk proses permohonan.
              </li>
              <li>
                <span style="color: blue;">
                  Jika anda tidak pasti, sila abaikan bahagian dokumen dan terus ke proses seterusnya dengan menjawab '<strong>Ya</strong>' pada pertanyaan 'Bantuan Khidmat Pelanggan'. Pihak koperasi akan menghubungi anda untuk bantuan seterusnya.
                </span>
              </li>
            </ol>
          `
          : `
            <strong>Kaedah menghantar dokumen-dokumen melalui online:</strong>
            <ol style="text-align: justify;">
              <li>
                Muat turun & cetak borang/surat dibahagian 1, dapatkan tandatangan/pengesahan & scan semula dalam bentuk PDF atau imej.
              </li>
              <li>
                Muat naik borang/surat dalam bentuk PDF/imej dibahagian 2 di ruang dokumen yang disediakan.
              </li>
              <li>
                Muat naik dokumen sokongan lain seperti salinan IC, penyata bank dan lain-lain di ruang yang disediakan.
              </li>
              <li>
                Senarai dokumen yang berwarna <span style="color: red;">merah*</span> adalah dokumen yang diperlukan untuk proses permohonan.
              </li>
            </ol>
          `,
        outlined: true,
        dismissable: false,
        bold: false,
        button1Caption: "",
        button2Caption: "",
        button3Caption: "",
        onAction1: () => { },
        onAction2: () => { },
        onAction3: () => { },
      };
    },

  },

  async created() {
    //nfh-console.log(" IN:CREATED")
    //nfh-console.log("this.propId:", this.propId);
    //nfh-console.log("this.propForm:", this.propForm);
    //nfh-console.log("this.propRoles:", this.propRoles);
    //nfh-console.log("this.propUseForm:", this.propUseForm);
    //nfh-console.log("OUT:CREATED")
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    // await this.getFormData();
    this.closeOverlay();
    //nfh-console.log("OUT:MOUNTED")
  },

  methods: {
    async init() {
      //nfh-console.log(' IN: init');
      if (this.guestStatus) {
        // await this.$store.dispatch(GET_LATEST_FORM_SESSION)
        //   .then((resp) => {
        //     if (!resp) {
        //       console.log('GET_LATEST_FORM_SESSION response: NULL', resp);
        //     }
        //     else {
        //       console.log('GET_LATEST_FORM_SESSION response: ', resp);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log('GET_LATEST_FORM_SESSION response: ERROR,', err);
        //   });
      }
      else {
        //nfh-console.log('SHOW_APPLICATION this.propId: ', this.propId);
        await this.$store
          .dispatch(SHOW_APPLICATION, this.propId)
          .then(async (resp) => {
            //nfh-console.log('SHOW_APPLICATION response: ', resp);
            if (resp.success) {
              const data = resp.data;
              data.employment.salary = data.employment.salary * 1;

              await this.$store.commit(SET_APPLICATION, data);
            }
          });
      }
      //nfh-console.log('OUT: init');
    },

    // async getFormData() {
    //   console.log(' IN: getFormData');
    //   await this.$store.dispatch(GET_CHECKLIST_FULL);
    //   await this.$store.dispatch(GET_LATEST_FORM_SESSION);
    //   console.log('OUT: getFormData');
    // },

    async prepareChecklist() {
      //nfh-console.log(' IN: prepareChecklist');
      const _res = await generateChecklist(this.propDocList, this.propertiesForm.id, this.isGuest);

      this.$store.commit(SET_CHECKLIST_DOWNLOAD_DATA, _res?.download);
      //nfh-console.log('_res.download:', _res?.download);

      this.$store.commit(SET_CHECKLIST_DATA, _res?.upload);
      //nfh-console.log('_res.upload:', _res?.upload);

      this.$store.commit(SET_CHECKLIST_STAT, _res?.stat);
      //nfh-console.log('_res.stat:', _res?.stat);

      // var _ret1 = [];
      // var _ret2 = [];
      // var _hasChecklist = false;
      // var _chklistStat = {
      //   total: 0,
      //   filled: 0,
      //   remain: 0,
      // };

      // console.log("this.propertiesForm:", this.propertiesForm);
      // if (Array.isArray(this.propDocList) && this.propertiesForm) {
      //   console.log("this.propDocList.length", this.propDocList.length);

      //   for (const el of this.propDocList) {
      //     if (el) {
      //       _hasChecklist = (el.checklist && String(el.checklist) === "[object Object]" && Object.keys(el.checklist).length > 0);

      //       let data = {
      //         name: el.name,
      //         roleid: el.pivot.role_id,
      //         mandatory: el.pivot.mandatory,
      //         fulfilled: _hasChecklist,
      //         master_id: this.propertiesForm.id,
      //         download_only: (_hasChecklist) ? el.checklist.download_only : el.download_only,
      //         checklist_masters_id: el.id,
      //         checklist_id: (_hasChecklist) ? el.checklist.id : "",
      //         media_name: (_hasChecklist) ? el.checklist.media_name : el.filename,
      //         media_url: (_hasChecklist) ? await getSecuredFile(el.checklist.media_url) : "",
      //         raw_url: (_hasChecklist) ? el.checklist.media_url : "",
      //       };

      //       if (this.isGuest) data.formid = this.propertiesForm.id;
      //       else data.appid = this.propertiesForm.id;

      //       if (el.download_only == true) {
      //         _ret1.push(data);
      //         console.log('PUSHED checklistDownloadData:', data);
      //       }
      //       else {
      //         _ret2.push(data);
      //         console.log('PUSHED checklistData:', data);

      //         if (data.mandatory) {
      //           _chklistStat.total++;
      //           _chklistStat.filled += _hasChecklist ? 1 : 0;
      //           _chklistStat.remain = _chklistStat.total - _chklistStat.filled;
      //         }
      //       }
      //     }
      //   }

      //   this.checklistDownloadData = _ret1;
      //   this.$store.commit(SET_CHECKLIST_DOWNLOAD_DATA, this.checklistDownloadData);
      //   console.log('checklistDownloadData:', this.checklistDownloadData);

      //   this.checklistData = _ret2;
      //   this.$store.commit(SET_CHECKLIST_DATA, this.checklistData);
      //   this.$store.commit(SET_CHECKLIST_STAT, _chklistStat);
      //   console.log('checklistData:', this.checklistData);
      // }
      //nfh-console.log('OUT: prepareChecklist');
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      this.overlay = false;
    },
  },

  beforeUpdate() {
    //nfh-console.log(" IN:BEFOREUPDATE");
    //nfh-console.log("OUT:BEFOREUPDATE");
  },

  updated() {
    //nfh-console.log(" IN:UPDATED");
    //nfh-console.log("OUT:UPDATED");
  },

  activated() {
    //nfh-console.log(" IN:ACTIVATED");
    //nfh-console.log("OUT:ACTIVATED");
  },

  deactivated() {
    //nfh-console.log(" IN:DEACTIVATED");
    //nfh-console.log("OUT:DEACTIVATED");
  },

  destroyed() {
    //nfh-console.log(" IN:DESTROYED");
    //nfh-console.log("OUT:DESTROYED");
  },

  beforeDestroy() {
    //nfh-console.log(" IN:BEFOREDESTROY");
    this.$store.commit(PURGE_CHECKLIST_MASTERS);
    this.uploadData = {};
    this.mediaIndex = null;
    this.guestStatus = undefined;
    // this.checklistData = [];
    this.memberChecked = false;
    this.mediaChecklist = null;
    this.openCalculatorDialog = false;
    // this.checklistDownloadData = [];
    // this.checklistFulfilmentCount = 0;
    //nfh-console.log("OUT:BEFOREDESTROY");
  },

  async beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    //nfh-console.log("OUT:BEFORECREATE");
  },

  async beforeMounted() {
    //nfh-console.log(" IN:BEFOREMOUNTED");
    //nfh-console.log("OUT:BEFOREMOUNTED");
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>
