var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-2", attrs: { height: "auto", elevation: "0" } },
    [
      _vm.alertDisplayable
        ? _c("AlertActionable", {
            attrs: {
              alertMessage: _vm.alert1.message,
              alertHtml: _vm.alert1.html,
              alertType: _vm.alert1.type,
              outlined: _vm.alert1.outlined,
              bold: _vm.alert1.bold,
              dismissable: _vm.alert1.dismissable,
              button1Caption: _vm.alert1.button1Caption,
              button2Caption: _vm.alert1.button2Caption,
              button3Caption: _vm.alert1.button3Caption,
            },
            on: {
              onAction1: _vm.alert1.onAction1,
              onAction2: _vm.alert1.onAction2,
              onAction3: _vm.alert1.onAction3,
            },
            model: {
              value: _vm.alert1.show,
              callback: function ($$v) {
                _vm.$set(_vm.alert1, "show", $$v)
              },
              expression: "alert1.show",
            },
          })
        : _vm._e(),
      _c(
        "ValidationObserver",
        { ref: "documentUploadFormValidation" },
        [
          _c(
            "v-form",
            { staticClass: "font-weight-bold font-size-sm" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c(
                            "h5",
                            { staticClass: "primary--text font-weight-bold" },
                            [_vm._v(" Dokumen Pengesahan ")]
                          ),
                          _c("v-divider", {
                            staticClass: "border-opacity-25",
                            attrs: { thickness: 2, color: "--v-primary-base" },
                          }),
                          _c("ul", [
                            _vm.propRoles.includes(2) ||
                            _vm.propRoles.includes(3) ||
                            _vm.propRoles.includes(5) ||
                            _vm.propRoles.includes(6)
                              ? _c(
                                  "li",
                                  { staticClass: "primary--text" },
                                  [
                                    _c("h6", { staticClass: "primary--text" }, [
                                      _c("strong", [_vm._v("BAHAGIAN 1:")]),
                                      _vm._v(" Sila "),
                                      _c("strong", [_vm._v("muat-turun")]),
                                      _vm._v(
                                        " borang-borang dibawah ini bagi mendapatkan tandatangan majikan "
                                      ),
                                    ]),
                                    _c("DocUploadListDownload", {
                                      attrs: {
                                        propMasterId: _vm.propertyFormId,
                                        propList: _vm.checklistDownloadStore,
                                        propReadOnly: _vm.isReadOnly,
                                        propGuest: _vm.isUseForm,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "li",
                              [
                                _c("h6", { staticClass: "primary--text" }, [
                                  _c("strong", [_vm._v("BAHAGIAN 2:")]),
                                  _vm._v(" Sila "),
                                  _c("strong", [_vm._v("muat-naik")]),
                                  _vm._v(
                                    " semula borang-borang yang sudah lengkap ditandatangani dibawah "
                                  ),
                                ]),
                                _c(
                                  "ValidationObserver",
                                  { ref: "checklistValidation" },
                                  [
                                    _c(
                                      "ValidationProvider",
                                      [
                                        _c("DocUploadList", {
                                          attrs: {
                                            propMasterId: _vm.propertyFormId,
                                            propList: _vm.checklistStore,
                                            propReadOnly: _vm.isReadOnly,
                                            propGuest: _vm.isUseForm,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-overlay",
            { attrs: { value: _vm.overlay } },
            [
              _c("v-progress-circular", {
                attrs: { indeterminate: "", size: "64" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }