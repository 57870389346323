<template>
  <div>
    <v-dialog
      v-model="displayCalculatorDialog"
      max-width="440"
    >
      <v-card
        class="pa-4"
      >
        <v-row>
          <v-col
            class="pa-0 d-flex flex-wrap justify-content-end"
            cols="12"
          >
            <v-btn
              icon
              light
            >
              <v-icon
                color="grey darken-2"
                @click="closeCalculatorDialog()"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>

          <v-col
            cols="12"
          >
            <v-card-title
              class="px-0 py-0">
              <p
                class="font-weight-bold"
              >
                {{ title }}
                <!-- {{ preCalc }} -->
              </p>
            </v-card-title>
          </v-col>

          <ValidationObserver
            ref="calculatorValidation"
          >
            <v-form
              class="font-weight-bold font-size-sm">
              <v-container
                fluid
              >
                <v-row
                  :align="alignCenter"
                  justify="center"
                >
                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <ValidationProvider
                      name="Gaji"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <vuetify-money
                        v-model="local.salary"
                        hide-details="auto"
                        :dense="moneyDense"
                        :error-messages="errors"
                        v-bind:label="label1"
                        v-bind:placeholder="placeholder1"
                        v-bind:tabindex=991
                        v-bind:options="currencyTwoDecimals"
                        v-bind:readonly="moneyWritable"
                        v-bind:disabled="moneyDisabled"
                        v-bind:outlined="moneyOutlined"
                        v-bind:clearable="moneyClearable"
                        v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                        v-bind:properties="moneyProperties"
                        required
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <ValidationProvider
                      name="Tanggungan Bulanan"
                      rules="required"
                      v-slot="{ errors }">
                      <vuetify-money
                        v-model="local.deductions"
                        hide-details="auto"
                        :dense="moneyDense"
                        :error-messages="errors"
                        v-bind:label="label2"
                        v-bind:placeholder="placeholder2"
                        v-bind:tabindex=992
                        v-bind:options="currencyTwoDecimals"
                        v-bind:readonly="moneyWritable"
                        v-bind:disabled="moneyDisabled"
                        v-bind:outlined="moneyOutlined"
                        v-bind:clearable="moneyClearable"
                        v-bind:properties="moneyProperties"
                        v-bind:valueWhenIsEmpty="moneyWhenEmpty"
                        required
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="12" md="12"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="KATEGORI MAJIKAN"
                      rules="required"
                    >
                      <v-select
                        v-model="local.employer"
                        label="KATEGORI MAJIKAN"
                        item-text="name"
                        item-value="id"
                        hide-details="auto"
                        v-bind:tabindex=993
                        :items="emp"
                        :error-messages="errors"
                        outlined
                        required
                        dense
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-btn
                      color="primary"
                      tabindex=994
                      @click="calculate()"
                      block
                    >
                      <span
                        class="font-weight-bold"
                      >
                        Kira
                      </span>
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="12"
                  >
                    <v-divider
                      class="mt-0"
                    ></v-divider>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                    sm="12"
                  >
                    <ValidationProvider
                      name="Tahun pinjaman"
                      rules="required"
                    >
                      <p
                      >
                        Sila Pilih Tempoh Bayaran Pinjaman (Tahun) :
                      </p>
                      <v-slider
                        v-model="slider"
                        min="1"
                        max="10"
                        tick-size="5"
                        ticks="always"
                        :disabled="!calcPressed"
                        :tick-labels="ticksLabels"
                        @change="getYear(tenure)"
                      ></v-slider>
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <span
                        class="mr-4"
                      >
                        Amaun Maksimum Pinjaman:
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <v-text-field
                        v-model="calculated.amt"
                        type="number"
                        hide-details="auto"
                        v-bind:step="100"
                        v-bind:min="calculated.min"
                        v-bind:max="calculated.max"
                        v-bind:disabled="!calcPressed"
                        @input="calcCustom"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </v-col>

                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <span
                        class="mr-4"
                      >
                        Kadar Keuntungan (%):
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <vuetify-money
                        v-model="calculated.rate"
                        hide-details="auto"
                        v-bind:placeholder="placeholder6"
                        v-bind:tabindex=996
                        v-bind:options="percentTwoDecimals"
                        v-bind:readonly="moneyReadOnly"
                        v-bind:disabled="moneyDisabled || !calcPressed"
                        v-bind:outlined="moneyOutlined"
                        v-bind:clearable="moneyUnclearable"
                        v-bind:valueWhenIsEmpty="placeholder5"
                        v-bind:properties="moneyProperties"
                        :dense="moneyDense"
                        :error-messages="errors"
                        required
                      />
                    </div>
                  </v-col>

                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <span
                        class="mr-4"
                      >
                        Ansuran Bulanan:
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    cols="6"
                  >
                    <div
                      class="d-flex align-items-center"
                    >
                      <vuetify-money
                        v-model="calculated.inst"
                        hide-details="auto"
                        :dense="moneyDense"
                        :error-messages="errors"
                        v-bind:placeholder="placeholder5"
                        v-bind:tabindex=997
                        v-bind:options="currencyTwoDecimals"
                        v-bind:readonly="moneyReadOnly"
                        v-bind:disabled="moneyDisabled || !calcPressed"
                        v-bind:outlined="moneyOutlined"
                        v-bind:clearable="moneyUnclearable"
                        v-bind:properties="moneyProperties"
                        v-bind:valueWhenIsEmpty="placeholder5"
                        required
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>

          <v-col
            class="d-flex flex-wrap justify-content-end align-items-center"
            cols="12"
          >
            <v-btn
              color="primary"
              :loading="isLoading"
              :disabled="isProceedDisabled"
              @click="onProceed()"
              depressed
              block
            >
              <v-row
                :align="alignCenter"
              >
                <v-col>
                  <span
                    class="font-weight-bold"
                  >
                    Mohon Amaun Ini : {{ calculated.amt | fAmount }}
                  </span>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-overlay
        :value="overlay"
      >
        <v-progress-circular
          indeterminate size="64"
        ></v-progress-circular>
      </v-overlay>

      <ErrorMsgDialog
        v-model="msgERR.show"
        proceedButton="OK"
        :title="msgERR.title"
        :content="msgERR.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        :isTypeError="true"
      ></ErrorMsgDialog>

      <InfoMsgDialog
        v-model="msgINFO.show"
        title="Info"
        proceedButton="OK"
        :content="msgINFO.message"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
      ></InfoMsgDialog>

      <v-overlay
        :value="overlay"
      >
        <v-progress-circular
          indeterminate size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import {
  required,
  max,
  numeric,
  integer,
} from "vee-validate/dist/rules";

import {
  isMapObject,
  errorMessages,
  promptError,
} from "@/core/services/helper.service";

import {
  CALCULATE,
  CALCULATE_APP,
  CALCULATE_GUEST,
  CALCULATE_SAVE,
  SET_CALC_RESULT,
} from "@/core/services/store/calculator.module";

import VuetifyMoney from "../vuetify/VuetifyMoney.vue";

// import VueSlider from "vue-slider-component";
// import "vue-slider-component/theme/antd.css";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} tidak boleh dibiarkan kosong",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("numeric", {
  ...numeric,
  message: "Nombor sahaja dibenarkan",
});

export default {
  name: "CalculatorDialog",

  props: {
    employerTypes: Array,

    salary: {
      type: [Number, String],
      default: 0,
    },

    employerTypesId: {
      type: Number,
      default: null,
    },

    applicationId: {
      type: Number,
      default: null,
    },

    formId: {
      type: Number,
      default: null,
    },

    deductions: {
      type: Number,
      default: 0,
    },

    value: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "",
    },

    preCalc: {
      type: String,
      default: "{}",
    },

    preData: {
      type: Object,
      default: () => { return {}; },
    },

  },

  components: {
    ValidationObserver,
    ValidationProvider,
    VuetifyMoney,
  },

  data() {
    return {
      slider: 0,
      overlay: false,
      isLoading: false,
      moneyDense: true,
      calcPressed: false,
      emp: {},
      financing_calc: "",

      local: {
        salary: 0,
        deductions: 0,
        employer: 0,
        amount: 0,
        tenure: 0,
        rate: 0,
        deds: 0,
        inst: 0,
      },

      calculated: {
        amt: 0,
        min: 0,
        max: 0,
        deds: 0,
        inst: 0,
        rate: 0,
        salary: 0,
        tenure: 0,
        inst_full: 0,
        deductions: 0,
        employerId: 0,
        eligible: false,
      },

      rulesLength: {
        name: [(v) => v.length <= 40 || "Max 40 huruf"],
        city: [(v) => v.length <= 30 || "Max 30 huruf"],
      },

      msgERR: {
        show: false,
        run: 0,
        nric: "",
        message: "",
        title: "Ralat",
      },

      msgINFO: {
        show: false,
        run: 0,
        nric: "",
        message: "",
      },

      moneyProperties: {
        error: true,
      },

      moneyReadOnly: true,
      moneyWritable: false,
      moneyEnabled: true,
      moneyDisabled: false,
      moneyOutlined: true,
      moneyClearable: true,
      moneyUnclearable: false,
      moneyWhenEmpty: "0",

      errors: "Ralat",
      label1: "Gaji Kasar Sebulan",
      label2: "Tanggungan Kasar Bulanan (Terkini)",
      label5: "Amaun Maksimum Pinjaman:",
      label6: "Kadar Keuntungan (%):",
      label7: "Ansuran Bulanan:",
      placeholder1: "1,500",
      placeholder2: "500.00",
      placeholder5: "0.00",
      placeholder6: "0",
      placeholder7: "0.00",
      loanAmountHint: "Minima RM 2,000 & Maksima RM 200,000",

      currencyNoDecimal: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 0,
        min: 1000,
        max: 1000000,
      },

      currencyTwoDecimals: {
        locale: "ms-MY",
        prefix: "RM",
        suffix: "",
        length: 9,
        precision: 2,
        min: 1000,
        max: 1000000,
      },

      percentTwoDecimals: {
        locale: "ms-MY",
        prefix: "",
        suffix: " %",
        length: 5,
        precision: 2,
        min: 0.00,
        max: 99.99,
      },

      ticksLabels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],

      alignCenter: "center",

    };
  },

  watch: {
    calculatedResult(newValue, oldValue) {
      const _tenure = (this.local.tenure > 0) ? this.local.tenure / 12 : this.calcData.tenure / 12;
      const _amount = (this.local.amount > 0) ? this.local.amount : null;
      this.getYear(_tenure, _amount);
    },

    salary(newValue, oldValue) {
      //nfh-console.log(`[WATCH] salary:\noldValue: ${oldValue}\nnewValue: ${newValue}`);

      this.local.salary      = newValue;
      this.calculated.salary = newValue;
      //nfh-console.log(`this.local.salary: ${this.local.salary}`);
    },

    deductions(newValue, oldValue) {
      //nfh-console.log(`[WATCH] deductions:\noldValue: ${oldValue}\nnewValue: ${newValue}`);

      this.local.deductions      = newValue;
      this.calculated.deductions = newValue;
      //nfh-console.log(`this.local.deductions: ${this.local.deductions}`);
    },

    employerTypesId(newValue, oldValue) {
      //nfh-console.log(`[WATCH] employerTypesId:\noldValue: ${oldValue}\nnewValue: ${newValue}`);

      this.local.employer        = newValue;
      this.calculated.employerId = newValue;
      //nfh-console.log(`this.local.employer: ${this.local.employer}`);
    },

    preCalc(newValue, oldValue) {
      //nfh-console.log(`[WATCH] preCalc:\noldValue: ${oldValue}\nnewValue: ${newValue}`);
      this.handlePreCalc(newValue);
    },

    preData(newValue, oldValue) {
      //nfh-console.log("[WATCH] preData:\noldValue: ", oldValue, "\nnewValue: ", newValue);
      this.handlePreData(newValue);
    },

  },

  async created() {
    this.emp = await JSON.parse(localStorage.getItem("emp"));
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    //nfh-console.log(`this.salary: ${this.salary}, this.deductions: ${this.deductions}, this.employerTypesId: ${this.employerTypesId}`);

    this.local.salary          = this.salary;
    this.local.deductions      = this.deductions;
    this.local.employer        = this.employerTypesId;
    this.calculated.salary     = this.salary;
    this.calculated.deductions = this.deductions;
    this.calculated.employerId = this.employerTypesId;

    this.handlePreCalc();
    this.handlePreData();

    //nfh-console.log(`this.calcPressed: ${this.calcPressed}`);
    //nfh-console.log(`this.slider: ${this.slider}`);
    //nfh-console.log(`this.calculated.tenure: ${this.calculated.tenure}`);

    //nfh-console.log("OUT:MOUNTED")
  },

  computed: {
    ...mapGetters([
      "calculatedResult",
      "formSession",
    ]),

    displayCalculatorDialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    isProceedDisabled() {
      //nfh-console.log("this.calculated.eligible && this.calcPressed", this.calculated.eligible, this.calcPressed)
      return !!!(this.calculated.eligible && this.calcPressed);
    },

    tenure: {
      get() {
        return this.slider;
      },
    },

    calcData: {
      get() {
        return {
          salary: this.local.salary,
          deductions: this.local.deductions,
          employerId: this.local.employer,
          tenure: this.slider,
          total: 0,
        }
      },
    }
  },

  methods: {
    handlePreCalc(preCalc = null) {
      //nfh-console.log("handlePreCalc:\npreCalc: ", preCalc, "\nthis.preCalc: ", this.preCalc);

      var _preCalcObj;

      _preCalcObj = {};
      preCalc     = preCalc ?? this.preCalc;
      //nfh-console.log("preCalc", preCalc);

      // Only if preCalc prop has value and it is a string (JSON)
      if (!this.calcPressed && preCalc && typeof preCalc === "string") {
        try {
          // parse JSON string into an object
          _preCalcObj = JSON.parse(preCalc);

          // Ensure the result is object contains calculation info
          if (isMapObject(_preCalcObj) && _preCalcObj?.success && Array.isArray(_preCalcObj?.sch)) {
            this.$store.commit(SET_CALC_RESULT, _preCalcObj);
            this.calcPressed    = true;
            this.financing_calc = preCalc;
            //nfh-console.log("handlePreCalc this.financing_calc: ", this.financing_calc);
          }
        }
        catch (err) {
          //nfh-console.log("handlePreCalc: <ERROR> ", err);
        }
      }
    },

    handlePreData(preData = null) {
      //nfh-console.log("handlePreData:\npreData: ", preData, "\nthis.preData: ", this.preData);

      preData = preData ?? this.preData;

      //
      // Pre-assign calculated paramters if assigned into preData
      //
      if (this.calcPressed && isMapObject(preData)) {
        if (preData?.deds)     this.local.deds   = parseFloat(preData?.deds);
        if (preData?.inst)     this.local.inst   = parseFloat(preData?.inst);
        if (preData?.rate)     this.local.rate   = parseFloat(preData?.rate);
        if (preData?.amount)   this.local.amount = parseFloat(preData?.amount);
        if (preData?.tenure)   this.local.tenure = parseInt(preData?.tenure);
        if (this.local.tenure) this.slider       = this.local.tenure / 12;

        if (this.slider > 0 && this.local.amount > 0) {
          this.getYear(this.slider, this.local.amount);
        }
      }
    },

    clearVars() {
      //nfh-console.log(" IN: clearVars");
      this.local.salary            = 0;
      this.local.deductions        = 0;
      this.local.employer          = null;
      this.calculated.amt          = 0;
      this.calculated.min          = 0;
      this.calculated.max          = 0;
      this.calculated.deds         = 0;
      this.calculated.inst         = 0;
      this.calculated.rate         = 0;
      this.calculated.salary       = 0;
      this.calculated.tenure       = 0;
      this.calculated.inst_full    = 0;
      this.calculated.deductions   = 0;
      this.calculated.employerId   = null;
      this.calculated.eligible     = false;
      this.calcPressed             = false;
      //nfh-console.log(`[clearVars]\nthis.local.*: ${this.local}\nthis.calculated.*: ${this.calculated}`);
      //nfh-console.log("OUT: clearVars");
    },

    async onProceed() {
      if (this.calculated.rate > 0 && this.calculated.inst > 0) {
        this.openOverlay();
        this.isLoading = true;

        const params = {};

        if (this.formId                >  0) params.form_id = this.formId;
        if (this.applicationId         >  0) params.app_id  = this.applicationId;
        if (this.calculated.employerId >  0) params.emp_id  = this.calculated.employerId;
        if (this.calculated.salary     >= 0) params.salary  = this.calculated.salary;
        if (this.calculated.tenure     >  0) params.tenure  = this.calculated.tenure;
        if (this.calculated.deds       >= 0) params.deduct  = this.calculated.deds;
        if (this.calculated.inst       >= 0) params.inst    = this.calculated.inst;
        if (this.calculated.rate       >  0) params.rate    = this.calculated.rate;
        if (this.calculated.amt        >= 0) params.amount  = this.calculated.amt;
        if (this.financing_calc            ) params.calc    = this.financing_calc;

        //nfh-console.log("onProceed this.financing_calc: ", this.financing_calc);
        //nfh-console.log("onProceed params: ", params);
        setTimeout(async () => {
          await this.$store.dispatch(CALCULATE_SAVE, params)
            .then((resp) => {
              //nfh-console.log("onProceed: ", resp);
              if (resp?.success) {
                resp.data = JSON.parse(Buffer.from(resp?.data, "base64"));
                //nfh-console.log("emit onGetResult: ", resp);
                this.$emit("onGetResult", resp);
              }
              else {
                this.promptError(resp.message);
              }
            })
            .catch((err) => {
              this.promptError(err);
            })
            .finally(() => {
              this.isLoading = false;
              this.closeOverlay();
              this.closeCalculatorDialog();
            });
        }, 1500);
      }
      else {
        if (this.calculated.eligible) {
          await this.promptError(`
          Amaun permohonan tidak sah. Pastikan anda buat pengiraan terlebih dahulu.
          `);
        }
        else {
          await this.promptError(`
          Amaun permohonan tidak sah. Pastikan anda buat pengiraan terlebih dahulu.
          `);
        }
      }
    },

    async calculate() {
      if (parseInt(this.local.deductions) > parseInt(this.local.salary)) {
        this.promptError("Tiada kelayakan jika tanggungan melebihi gaji.", "Kelayakan");
        //nfh-console.log("this.local.deductions: ", this.local.deductions);
        //nfh-console.log("this.local.salary: ", this.local.salary);
      }
      else {
        const params = {};

        params.salary     = this.local.salary,
        params.employer   = this.local.employer,
        params.deductions = this.local.deductions,
        params.form_id    = this.formId        > 0 ? this.formId        : undefined;
        params.app_id     = this.applicationId > 0 ? this.applicationId : undefined;

        //nfh-console.log("CALCULATE params: ", params);

        await this.$store.dispatch(CALCULATE, params)
          .then((resp) => {
            //nfh-console.log("CALCULATE response: ", resp);

            if (resp?.success) {
              //nfh-console.log("CALCULATE result: ", resp);
              this.calcPressed = true;

              if (resp.eligible) {
                this.financing_calc = JSON.stringify(resp);
                //nfh-console.log("calculate this.financing_calc: ", this.financing_calc);
                this.$store.commit(SET_CALC_RESULT, resp);
                this.$emit("calculate", resp);
              }
              else {
                this.promptError("Tiada kelayakan jika tanggungan melebihi kadar potongan maksima dibenarkan.", "Kelayakan");
              }
            }
            else {
              this.promptError(resp?.message);
            }
          });
      }
    },

    getYear(selectedYear, customAmount = null) {
      //nfh-console.log("getYear(selectedYear)", selectedYear);
      //nfh-console.log("this.calculatedResult: ", this.calculatedResult);

      if (this.calculatedResult && Array.isArray(this.calculatedResult.sch)) {
        const idx = Math.min(Math.max(selectedYear - 1, 0), this.calculatedResult.sch.length);

        if (this.calculatedResult) {
          this.slider                = selectedYear;
          this.calculated.tenure     = selectedYear * 12;
          this.calculated.amt        = this.calculatedResult.sch[idx].amt;
          this.calculated.min        = this.calculatedResult.sch[idx].amt < 3000 ? 0 : 3000;
          this.calculated.max        = this.calculatedResult.sch[idx].amt;
          this.calculated.rate       = this.calculatedResult.sch[idx].rate;
          this.calculated.inst       = this.calculatedResult.sch[idx].inst;
          this.calculated.deds       = this.calculatedResult.param.deductions; //this.calcData.deductions;
          this.calculated.inst_full  = this.calculatedResult.sch[idx].inst_full;
          this.calculated.salary     = this.calculatedResult.param.salary;
          this.calculated.deductions = this.calculatedResult.param.deductions;
          this.calculated.employerId = this.calculatedResult.param.employer_id;
          this.calculated.eligible   = this.calculatedResult.eligible;

          if (customAmount) {
            this.calcCustom(customAmount);
          }
        }
      }
    },

    calcCustom(financingAmount) {
      const val  = parseFloat(financingAmount);
      const amt  = parseFloat((val > parseFloat(this.calculatedResult?.sch[this.slider - 1].amt)) ? parseFloat(this.calculatedResult?.sch[this.slider - 1].amt) : val);
      const inst = (amt + (+amt * ((parseFloat(this.calculatedResult?.sch[this.slider - 1].rate) / 100.0) * +this.slider))) / (+this.slider * 12.0);

      this.calculated.amt  = amt;
      this.calculated.inst = inst;
    },

    async promptError(message, title) {
      //nfh-console.log("promptError: ", errorMessages(message));
      this.msgERR.message = errorMessages(message);
      this.msgERR.title = title ? title : "Ralat";
      this.openErrMsgDialog();
    },

    promptInfo(message) {
      this.msgINFO.message = message;
      this.openInfoMsgDialog();
    },

    openInfoMsgDialog() {
      this.msgINFO.show = true;
    },

    openErrMsgDialog() {
      this.msgERR.show = true;
    },

    openOverlay() {
      this.overlay = true;
    },

    closeOverlay() {
      setTimeout(() => {
        this.overlay = false;
      }, 1500);
    },

    closeCalculatorDialog() {
      // this.clearVars();
      this.displayCalculatorDialog = false;
    },

  },

  beforeUpdate() {
    //nfh-console.log(" IN:BEFOREUPDATE");
    //nfh-console.log("OUT:BEFOREUPDATE");
  },

  updated() {
    //nfh-console.log(" IN:UPDATED");
    //nfh-console.log("OUT:UPDATED");
  },

  activated() {
    //nfh-console.log(" IN:ACTIVATED");
    //nfh-console.log("OUT:ACTIVATED");
  },

  deactivated() {
    //nfh-console.log(" IN:DEACTIVATED");
    //nfh-console.log("OUT:DEACTIVATED");
  },

  destroyed() {
    //nfh-console.log(" IN:DESTROYED");
    //nfh-console.log("OUT:DESTROYED");
  },

  beforeDestroy() {
    //nfh-console.log(" IN:BEFOREDESTROY");
    // this.clearVars();
    //nfh-console.log("OUT:BEFOREDESTROY");
  },

  async beforeCreate() {
    //nfh-console.log(" IN:BEFORECREATE");
    //nfh-console.log("OUT:BEFORECREATE");
  },

  async beforeMounted() {
    //nfh-console.log(" IN:BEFOREMOUNTED");
    //nfh-console.log("OUT:BEFOREMOUNTED");
  },
};
</script>

<style scoped>
.v-text-field--outlined {
  font-size: 10pt;
}

.v-text-field label {
  font-size: 10pt;
}
</style>
