var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "800" },
      model: {
        value: _vm.showUploadManager,
        callback: function ($$v) {
          _vm.showUploadManager = $$v
        },
        expression: "showUploadManager",
      },
    },
    [
      _c("v-card", { staticClass: "pa-6" }, [
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-hover vertical-align" }, [
              _c("thead", [
                _c("tr", { staticClass: "text-center" }, [
                  _c("th", [_vm._v("#")]),
                  _c("th", [_vm._v("Imej")]),
                  _c("th", [_vm._v("Nama")]),
                  _c("th", [_vm._v("Saiz")]),
                  _c("th", [_vm._v("Status")]),
                  _c("th", [_vm._v("Tindakan")]),
                ]),
              ]),
              _c("tbody", [
                !_vm.file
                  ? _c(
                      "tr",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$refs.fileUpload.click()
                          },
                        },
                      },
                      [
                        _c("td", { attrs: { colspan: "12" } }, [
                          _c("div", { staticClass: "text-center pa-4" }, [
                            _vm._v(" " + _vm._s(_vm.propFile.name) + " "),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.file
                  ? _c("tr", { staticClass: "text-center" }, [
                      _c("td", [_vm._v(_vm._s(_vm.file ? 1 : ""))]),
                      _c("td", [
                        _vm.mediaImage
                          ? _c("img", {
                              staticClass: "td-image-thumb",
                              attrs: { src: this.filePreview },
                            })
                          : _c("span", [_vm._v("No Image")]),
                      ]),
                      _c("td", [
                        _c(
                          "div",
                          {
                            staticClass: "filename",
                            staticStyle: {
                              "white-space": "nowrap",
                              "max-width": "200px",
                              "text-overflow": "ellipsis",
                              overflow: "hidden",
                              display: "inline-block",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.file ? _vm.file.name : "") + " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "progress" }, [
                          _c(
                            "div",
                            {
                              class: {
                                "progress-bar": true,
                                "bg-danger": _vm.file.error,
                              },
                              style: { width: _vm.progress + "%" },
                              attrs: { role: "progressbar" },
                            },
                            [_vm._v(" " + _vm._s(_vm.progress) + "% ")]
                          ),
                        ]),
                      ]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatSize")(_vm.file ? _vm.file.size : 0)
                            ) +
                            " "
                        ),
                      ]),
                      _vm.file.error
                        ? _c("td", [_vm._v(_vm._s(_vm.file.error))])
                        : _c("td", [_vm._v(_vm._s(_vm.status))]),
                      _c(
                        "td",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "btn btn-icon btn-light btn-hover-primary btn-sm",
                              attrs: { icon: "" },
                              on: { click: _vm.clear },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "svg-icon svg-icon-md svg-icon-primary",
                                },
                                [
                                  _c("inline-svg", {
                                    attrs: {
                                      src: "/media/svg/icons/General/Trash.svg",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "upload" }, [
            _c("div", { staticClass: "btn-group" }, [
              _c("input", {
                ref: "fileUpload",
                staticStyle: { display: "none" },
                attrs: { type: "file" },
                on: { change: _vm.runUploadFile, click: _vm.inputClick },
              }),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    change: _vm.runUploadFile,
                    click: function ($event) {
                      return _vm.$refs.fileUpload.click()
                    },
                  },
                },
                [_vm._v(" Pilih Dokumen ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }